<template>
    <div  id="components-form-demo-advanced-search">
        <a-form class="ant-advanced-search-form">
            <a-row :gutter="24">
                <a-col :span="8" >
                    <a-form-item label="小区名称">  <a-input v-model="params.centerName"  allowClear/> </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="区域名称">  <a-input v-model="params.areaName"   allowClear/> </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="街道名称">  <a-input v-model="params.streetName" allowClear/> </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="选择时间">
                        <a-date-picker @change="onChangeDate" v-model="params.analysisDate" allowClear/>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="是否核验" >  
                        <a-select allowClear @change="handleChange" style="width: 120px;">
                            <a-select-option :value="true">
                                是
                            </a-select-option>
                            <a-select-option :value="false">
                                否
                            </a-select-option>
                        </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="核验结果">
                        <a-select allowClear @change="handleChangeUnanimous" style="width: 120px;">
                            <a-select-option :value="true">
                                一致
                            </a-select-option>
                            <a-select-option :value="false">
                                不一致
                            </a-select-option>
                            </a-select>
                    </a-form-item>
                </a-col>
                <a-col :span="8">
                    <a-form-item label="备注信息">
                            <a-input v-model="params.remarks" allowClear/>
                    </a-form-item>
                </a-col>
            </a-row>
            <a-row>
                <a-col :span="24" :style="{ textAlign: 'right' }">
                    <a-button type="primary" @click="onSearch" style="margin-right: 10px;">  查询 </a-button>
                    <a-button type="primary" @click="showFlowInit" style="margin-right: 10px;">  初始化数据 </a-button>
                </a-col>
            </a-row>
        </a-form>
        <a-row justify="end" type="flex" style="margin-top:10px;">
          <a-col :span="3">
            <download-excel :data="jsonData" :fields="jsonFields" name="数据对比.xls" type="xls" worksheet="数据对比" >
              <a-button> 下载所有不一致小区 </a-button>
            </download-excel>
          </a-col>
        </a-row>
        <a-table bordered :columns="columns" :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"  :data-source="tableData" style="margin-top: 20px;" :loading="loading" :pagination="pagination" >
            <template slot-scope="text, record" slot="administrativeNumber">
                <span @click="copyQuick(record.administrativeNumber)"  >{{record.administrativeNumber}}</span>
            </template>
            <template slot-scope="text, record" slot="centerName">
                <span @click="copyQuick(record.centerName)" >{{record.centerName}}</span>
            </template>
            <template slot-scope="text, record" slot="countOtherSystem">
                <a-input
                v-if="record.editable"
                  style="margin: -5px 0;width:200px;"
                  :value="text"
                  @change="e => handleChangeInput(e.target.value, record, 'countOtherSystem')"
                />
                 <span v-if="!record.editable">{{text}}</span>
            </template>
            <template slot-scope="text, record" slot="remarks">
                <a-input
                v-if="record.editable"
                  style="margin: -5px 0;width:200px;"
                  :value="text"
                  @change="e => handleChangeInput(e.target.value, record, 'remarks')"
                />
                 <span v-if="!record.editable">{{text}}</span>
            </template>
            <template slot-scope="text, record" slot="isWrite">
                <a-tag :color="record.isWrite ? 'green':'red'">
                    {{record.isWrite ? '已核验' : '未核验'}}
                </a-tag>
            </template>
            <template slot-scope="text, record" slot="unanimous">
                <a-tag :color="record.unanimous ? 'green':'red'">
                    {{record.unanimous ? '一致' : (record.isWrite ? '不一致': '')}}
                </a-tag>
            </template>
            <span slot="action" slot-scope="text, record, index">
                <div class="editable-row-operations">
                  <span v-if="record.editable">
                    <a @click="() => save(record)">保存</a>
                    <a-popconfirm title="确定取消?" @confirm="cancel(record)"  @cancel="closePop" style="margin-left: 20px">
                      <a>取消</a>
                    </a-popconfirm>
                  </span>
                  <span v-else>
                    <a :disabled="isEdit" @click="() => edit(record, index)">编辑</a>
                  </span>
                </div>
            </span>
        </a-table>
        <a-modal v-model="flowVisible" title="初始化数据" @ok="queryFlowInit" okText="确定" cancelText="取消">
          <a-form>
            <a-form-item label="Token">
                  <a-input v-model="Token" style="width: 350px;"/>
            </a-form-item>
          </a-form>
        </a-modal>
    </div>
</template>

<script>
import mixin from '@/utils/mixin';
import moment from 'moment'
import { UpdateFlowApi, queryFlowInitApi, queryFlowAnalysisApi} from '@/api/compare'
const columns = [
                {
                    title: '序号',
                    dataIndex: 'id',
                    key: 'id'
                },
                {
                    title: '市级编码',
                    dataIndex: 'administrativeNumber',
                    key: 'administrativeNumber',
                    scopedSlots: {customRender: 'administrativeNumber'}
                },
                {
                    title: '小区名称',
                    dataIndex: 'centerName',
                    key: 'centerName',
                    scopedSlots: {customRender: 'centerName'}
                },
                {
                    title: '所属区域',
                    dataIndex: 'areaName',
                    key: 'areaName'
                },
                {
                    title: '所属街道',
                    dataIndex: 'streetName',
                    key: 'streetName',
                },
                {
                    title: '数据中台数据数量',
                    dataIndex: 'countSelfSystem',
                    key: 'countSelfSystem',
                },
                {
                    title: '市级平台数据数量',
                    dataIndex: 'countOtherSystem',
                    key: 'countOtherSystem',
                    editable: false,
                    scopedSlots: {customRender: 'countOtherSystem'}
                },
                {
                    title: '备注',
                    dataIndex: 'remarks',
                    key: 'remarks',
                    editable: false,
                    scopedSlots: {customRender: 'remarks'}
                },
                {
                    title: '是否核验',
                    dataIndex: 'isWrite',
                    key: 'isWrite',
                    scopedSlots: {customRender: 'isWrite'}
                },
                {
                    title: '核验结果',
                    dataIndex: 'unanimous',
                    key: 'unanimous',
                    scopedSlots: {customRender: 'unanimous'}
                },
                {
                    title: '操作',
                    dataIndex: 'action',
                    scopedSlots: {customRender: 'action'}
                },
       ]
export default {
  mixins: [mixin],
  data() {
    return {
      jsonData: [],
      jsonFields: {},
      date: moment().subtract(1, "days").format('YYYY-MM-DD'),
      isEdit: false,
      selectedRowKeys: [],
      confirmLoading: false,
      showModal: false,
      params: {
          centerName: '',
          areaName: '',
          streetName: '',
          analysisDate: moment().subtract(1, "days"),
          isWrite: '',
      },
      pagination: {
        pageSize: 10,
        current: 1,
        total: 0,
        showQuickJumper: true,
        showSizeChanger: true,
        showTotal: (total) => {
          return '共' + total + '个归集点信息'
        },
        pageSizeOptions: ['10', '20', '30', '40', '50', '60', '70'],
        onChange: (current, size) => this.changePage(current, size),
        onShowSizeChange:(current, pageSize)=>this.changePage(current, pageSize),
      },
      loading: false,
      tableData: [],
      columns,
      tmpTarget: {},
      flowVisible: false,
      Token: ''
    };
  },
  mounted() {
    this.queryFlowAnalysis()
    this.jsonFields = {}
    this.columns.forEach((item, index) => {
      if(index < this.columns.length - 1) {
        this.jsonFields[item.title] = item.dataIndex
      }
    })

  },
  methods: {
    showFlowInit() {
      this.flowVisible = true
      this.Token = ''
    },
    queryFlowInit() {
        queryFlowInitApi(this.Token).then(res => {})
    },
    onChangeDate(e) {
        this.params.analysisDate = e
    },
    handleChange(value){
        this.params.isWrite = value
    },
    handleChangeUnanimous(value){
        this.params.unanimous = value
    },
    changePage(current, size){
        this.pagination.current = current
        this.pagination.pageSize = size
        this.queryFlowAnalysis()
    },
    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },
    handleChangeInput(value, record, name) {
      const newData = [...this.tableData];
      const target = newData.filter(item => record.id === item.id)[0];
      if (target) {
        target[name] = value;
      }
      this.tmpTarget = target
    },
    save() {
      this.isEdit = false
      UpdateFlowApi({...this.tmpTarget}).then(res => {
        this.queryFlowAnalysis()
        this.$message.success('修改成功')
      }).catch(() => {
         this.$message.eroor('修改失败')
      })
    },
    cancel() {
      this.isEdit = false
      this.queryFlowAnalysis()
    },
    edit(record, index) {
      const newData = [...this.tableData];
      const target = newData.filter(item => record.id === item.id)[0];
      target.editable = true
      this.tableData = newData
      this.isEdit = true
      this.tmpTarget = target

    },
    onSearch() {
      this.pagination.current = 1
      this.pagination.total = 0
      this.queryFlowAnalysis()
    },
    queryUnanimousData() {
      let param = {
        page: 1,
        limit: 1000,
        unanimous: false
      }
      queryFlowAnalysisApi({...param}).then(res => {
        this.jsonData = res.result.content
        this.jsonData.forEach(item => {
          item.isWrite = item.isWrite ? '是' : '否'
          item.unanimous = item.unanimous ? '一致' : '不一致'
        })
      })
    },
    queryFlowAnalysis() {
      this.loading = true
      this.params.page = this.pagination.current
      this.params.limit = this.pagination.pageSize
      queryFlowAnalysisApi({...this.params}).then(res => {
        this.loading = false
        this.tableData = res.result.content
        this.tableData.forEach((item, index) => {
          item.editable = false
        })
        this.pagination.current = res.result.number + 1
        this.pagination.total = res.result.totalElements
        this.queryUnanimousData()
      })
    }
  }
};
</script>

<style scoped>
.index{
    /* background: #F5F7F9; */
    padding: 15px;
    box-sizing: border-box;
    width: 100%;
    min-height: 100%;
}
.ant-form{
    text-align: initial;
}
.ant-form-item{
    margin-bottom: 10px;
}
.ant-advanced-search-form {
  padding: 12px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}

#components-form-demo-advanced-search .ant-form {
  max-width: none;
}
</style>